import React from "react";
import { Card, CardBody, CardTitle, Button } from "reactstrap";

const ProgramCard = props => (
  <Card className="programCard">
    <CardBody>
      <CardTitle>
        <h3 className="programTitle">{props.name}</h3>
      </CardTitle>
      <Button value={props._id} className="openProgramBtn" color="success" onClick={props.openProgram}>
        신청하기
      </Button>
    </CardBody>
  </Card>
);

export default ProgramCard;
