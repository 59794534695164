import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Row,
  Col,
} from "reactstrap";
import { Document, Page } from "react-pdf";

const AnnouncementModal = (props) => (
  <Modal isOpen={props.announcementModal} toggle={props.toggle} size="lg">
    <ModalHeader toggle={props.toggle}>{props.announcementTitle}</ModalHeader>
    <ModalBody>
        <Row>
          <Col>
            <Button onClick={props.goToPrevPage}>Prev</Button>
          </Col>
          <Col>
            <Button onClick={props.goToNextPage} className="aModalBtn">Next</Button>
          </Col>
        </Row>
        <Row>
          <Col lg="1"></Col>
          <Col lg="10" md="12" sm="12" xs="12">
            <Document
              file={props.pdfLink}
              onLoadSuccess={props.onDocumentLoadSuccess}
              onLoadError={console.error}
            >
              <Page pageNumber={props.pageNumber} width={600} />
            </Document>
          </Col>
          <Col lg="1"></Col>
        </Row>
        <Row>
          <Col>
            <p>
              Page {props.pageNumber} of {props.numPages}
            </p>
          </Col>
        </Row>
    </ModalBody>
  </Modal>
);

export default AnnouncementModal;
