import React from "react";
import { Form, FormGroup, Col, Label, Input, Button } from "reactstrap";

const AddQuestionForm = (props) => (
  <Form onSubmit={props.addQuestion}>
    <FormGroup row>
      <Label sm={4}>Question Name</Label>
      <Col sm={6}>
        <Input
          name="qName"
          onChange={props.changeHandler}
          placeholder="Enter Question Name"
          value={props.qName}
        />
      </Col>
      <Col sm={2}>
        <Button onClick={props.createQuestion}>Add</Button>
      </Col>
    </FormGroup>
  </Form>
);

export default AddQuestionForm;
