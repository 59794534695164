import React from "react";
import ProgramList from "./ProgramList";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Button
} from "reactstrap";

class HomePage extends React.Component {
  state = {
    programs: [],
  };

  componentDidMount() {
    this.getPrograms();
  }

  getPrograms = (event) => {
    axios({
      method: "GET",
      url: "/api/programs",
    }).then((programs) => {
      const url = window.location.pathname;
      const congregation = url.substring(1);
      const sortedPrograms = [];
      for (let i = 0; i < programs.data.length; i++) {
        if (programs.data[i].congregation.toLowerCase() === congregation) {
          sortedPrograms.push(programs.data[i]);
        }
      }
      this.setState({ programs: sortedPrograms });
    });
  };

  openProgram = (event) => {
    event.preventDefault();
    const url = window.location.pathname;
    const congregation = url.substring(1);
    window.location.href = `/${congregation}/program/${event.target.value}`;
  };

  openAnnouncements = (event) => {
    event.preventDefault();
    window.location.href = "/sk/announcements";
  };

  openZoomM() {
    window.open(
      "https://jworg.zoom.us/j/6565168846?pwd=c2pLaHFNajVLYW1lTHBqb01VcElTdz09",
      "_system"
    );
  }

  openZoomFS() {
    window.open(
      "https://us04web.zoom.us/j/9245595883?pwd=cFV4UldtZlJpM0VNdUZxbXhyVHQ4Zz09",
      "_system"
    );
  }

  render() {
    return (
      <Container>
        <header>
          <h1 className="title">해설 신청하기</h1>
          {window.location.pathname.substring(1) === "sk" ? (
            <Button
              className="announceBtn"
              color="info"
              onClick={this.openAnnouncements}
            >
              게시판
            </Button>
          ) : (
            ""
          )}
        </header>
        <hr />
        <Row>
          <Col sm="6" xs="12">
            <ProgramList
              programs={this.state.programs}
              openProgram={this.openProgram}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default HomePage;
