import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Collapse,
  Button,
  Alert,
  Row,
  Col,
} from "reactstrap";
import AddQuestionForm from "./AddQuestionForm";

const QuestionsModal = (props) => (
  <Modal isOpen={props.questionsModal} toggle={props.toggle}>
    <ModalHeader toggle={props.toggle}>{props.activePName}</ModalHeader>
    <ModalBody>
      {props.yAlert ? <Alert color="warning">Question Deleted</Alert> : ""}
      <Table>
        <tbody>
          {props.questions && props.questions.length > 0 ? (
            props.questions.map((question) => (
              <tr key={question._id}>
                <td>{question.qName}</td>
                <td>
                  <Button value={question._id} onClick={props.deleteQuestion}>Delete</Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td>No Questions Exist</td>
            </tr>
          )}
        </tbody>
      </Table>
    </ModalBody>
    <ModalFooter>
      <Row>
        <Col>
          <Button onClick={props.toggleCollapse} className="toggleBtn">
            Add Questions
          </Button>
        </Col>
      </Row>
      <Collapse isOpen={props.isOpen}>
        {props.rAlert2 ? (
          <Alert color="danger">Please enter a question name!</Alert>
        ) : (
          ""
        )}
        {props.gAlert2 ? <Alert color="success">Question added!</Alert> : ""}
        <AddQuestionForm
          changeHandler={props.changeHandler}
          qName={props.qName}
          createQuestion={props.createQuestion}
        />
      </Collapse>
    </ModalFooter>
  </Modal>
);

export default QuestionsModal;
