import React from "react";
import QuestionCard from "./QuestionCard";

const QuestionList = props => (
  <tbody>
    {props.questions &&
      props.questions.map(question => (
        <QuestionCard
          key={question._id}
          toggle={props.toggle}
          {...question}
        />
      ))}
  </tbody>
);

export default QuestionList;
