import React from "react";
import ProgramCard from "./ProgramCard";

const ProgramList = props => (
    <div>
        {props.programs && props.programs.map(program => <ProgramCard key={program._id} {...program} openProgram={props.openProgram}/>)}
    </div>
)
  

export default ProgramList;
