import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Row,
  Col,
  Input,
  Label,
  FormGroup,
  Form,
  Alert,
  ModalFooter,
} from "reactstrap";

const PioModal = (props) => (
  <Modal isOpen={props.pioModal} toggle={props.toggle} size="lg">
    <ModalHeader toggle={props.toggle}>보조 파이오니아 봉사 지원서</ModalHeader>
    <ModalBody>
      {props.emptyAlert ? (
        <Alert color="warning">
          월(들), 일자, 혹은 서명을 입력하지 않으셨습니다!
        </Alert>
      ) : (
        ""
      )}
      <Form>
        <Row form>
          <Col>
            <h4 className="pioModalText">
              나는 여호와에 대한 사랑에서 우러나와, 그분과 그분의 사랑에 찬
              목적에 관해 다른 사람들도 배워 알도록 간절히 돕고자 하여, 아래
              기록된 기간 보조 파이오니아로 등록함으로써 야외 봉사에 참여하는
              나의 몫을 증가시키고자 합니다.
            </h4>
          </Col>
        </Row>
        <Row form>
          <Col lg="11" md="10" sm="10" xs="9">
            <Input
              onChange={props.changeHandler}
              value={props.month}
              name="month"
              placeholder="월(들)"
            />
          </Col>
          <Col lg="1" md="2" sm="2" xs="3">
            <Label>월(들)</Label>
          </Col>
        </Row>
        <Row form>
          <Col>
            <FormGroup check>
              <Label className="checkLabelText">
                <Input
                  onChange={props.checkChange}
                  checked={props.checked}
                  type="checkbox"
                />{" "}
                다시 통지할 때까지 계속 보조 파이오니아로서 봉사하기를 원한다면
                여기에 표시하십시오.
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col>
            <h5 className="pioModalText">
              나는 훌륭한 도덕적 신분과 습관을 가지고 있습니다. 나는 요구되는
              시간에 달할 수 있도록 계획하였습니다. - <a href="https://wol.jw.org/ko/wol/d/r8/lp-ko/202013206">[우리의 왕국 봉사] 2013년
              6월호 2면</a> 참조.
            </h5>
          </Col>
        </Row>
        <Row form>
          <Col lg="1" md="2" sm="2" xs="2">
            <Label> 일자: </Label>
          </Col>
          <Col lg="11" md="10" sm="10" xs="10">
            <Input
              onChange={props.changeHandler}
              value={props.date}
              name="date"
            />
          </Col>
        </Row>
        <Row form>
          <Col lg="1" md="2" sm="2" xs="2">
            <Label>이름:</Label>
          </Col>
          <Col lg="11" md="10" sm="10" xs="10">
            <Input
              onChange={props.changeHandler}
              value={props.signature}
              name="signature"
              placeholder="이름을 입력해주세요 (서명을 대신합니다)"
              className="signatureInput"
            />
          </Col>
        </Row>
      </Form>
    </ModalBody>
    <ModalFooter>
      <Button onClick={props.sendPioneerForm} className="pioBtn" color="success">신청하기</Button>
    </ModalFooter>
  </Modal>
);

export default PioModal;
