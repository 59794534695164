import React from "react";
import QuestionList from "./QuestionList";
import RegisterModal from "./RegisterModal";
import axios from "axios";
import {
  Container,
  Table,
  Alert
} from "reactstrap";

class ProgramPage extends React.Component {
  state = {
    name: "",
    inputName: "",
    questions: [],
    gAlert: false,
    rAlert: false,
    yAlert: false,
    questionInput: "",
    registerModal: false,
    questionID: "",
    activeQName: ""
  };

  componentDidMount() {
    this.getPrograms();
  }

  componentDidUpdate(prevState) {
    if (prevState.gAlert !== this.state.gAlert) {
      window.scrollTo(0, 0);
    }
  }

  goHome = event => {
    event.preventDefault();
    const url = window.location.pathname;
    const congregation = url.substring(1, url.indexOf("/", 1));
    window.location.href = "/" + congregation;
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  toggleRegisterModal = event => {
    this.getQuestion(event.target.value)
    this.setState({
      registerModal: !this.state.registerModal,
      questionID: event.target.value,
      gAlert: false,
      rAlert: false
    });
  };

  getPrograms = event => {
    const url = window.location.pathname;
    const programIndex = url.indexOf("m");
    const programID = url.substring(programIndex + 2);
    axios({
      method: "GET",
      url: `/api/programs/${programID}`
    }).then(program => {
      this.setState({
        name: program.data.name,
        questions: program.data.questions
      });
    });
  };

  registerQuestion = event => {
    event.preventDefault();
    if (this.state.inputName) {
      axios({
        method: "PUT",
        url: `/api/questions/${this.state.questionID}`,
        data: {
          pubName: this.state.inputName,
          availability: false
        }
      }).then(question => {
        if (question.data.error) {
          this.getPrograms();
          this.setState({
            gAlert: false,
            rAlert: false,
            yAlert: true,
            registerModal: false,
            inputName: ""
          });
        } else {
          this.getPrograms();
          this.setState({
            gAlert: true,
            rAlert: false,
            registerModal: false,
            inputName: ""
          });
        }
      });
    } else {
      this.setState({ rAlert: true, gAlert: false });
    }
  };

  getQuestion(questionID) {
    axios({
      method: "GET",
      url: `/api/questions/${questionID}`
    }).then(activeQuestion => {
      this.setState({activeQName: activeQuestion.data.qName});
    });;
  }

  createQuestion = async event => {
    event.preventDefault();
    const url = window.location.pathname;
    const programIndex = url.indexOf("m");
    const programID = url.substring(programIndex + 2);
    if (this.state.questionInput) {
      await axios({
        method: "POST",
        url: "/api/questions",
        data: {
          qName: this.state.questionInput,
          availability: true,
          program: programID
        }
      }).then(newQ => {
        this.updateProgram(newQ);
      });
    }
  };

  updateProgram = async newQ => {
    const newQuestionArr = [];
    for (let i = 0; i < this.state.questions.length; i++) {
      newQuestionArr.push(this.state.questions[i]._id);
    }
    newQuestionArr.push(newQ.data._id);
    const url = window.location.pathname;
    const programIndex = url.indexOf("m");
    const programID = url.substring(programIndex + 2);
    await axios({
      method: "PUT",
      url: `/api/programs/${programID}`,
      data: {
        questions: newQuestionArr
      }
    }).then(program => {
      this.getPrograms();
      this.setState({ questionInput: "" });
    });
  };

  render() {
    return (
      <Container>
        <h2 className="programTitle1">{this.state.name}</h2>
        <i className="fas fa-times" onClick={this.goHome}></i>
        {this.state.gAlert ? (
          <Alert color="success">{this.state.activeQName} 신청하셨습니다. 열심히 준비하세요!</Alert>
        ) : (
          ""
        )}
        {this.state.yAlert ? (
          <Alert color="warning">
            다른 분이 먼저 신청했습니다. 다른 질문에 대한 해설을 신청해 주세요.
          </Alert>
        ) : (
          ""
        )}
        <Table responsive>
          <thead>
            <tr>
              <th>질문 #</th>
              <th></th>
            </tr>
          </thead>
          <QuestionList
            questions={this.state.questions}
            toggle={this.toggleRegisterModal}
          />
        </Table>
        <RegisterModal
          changeHandler={this.changeHandler}
          inputName={this.state.inputName}
          registerQuestion={this.registerQuestion}
          toggle={this.toggleRegisterModal}
          registerModal={this.state.registerModal}
          rAlert={this.state.rAlert}
          activeQName={this.state.activeQName}
        />
      </Container>
    );
  }
}

export default ProgramPage;
