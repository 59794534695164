import React from "react";
import { Table, Button } from "reactstrap";

const ProgramList = (props) => (
  <Table responsive>
    <tbody>
      {props.programs && props.programs.length > 0 ? (
        props.programs.map((program) => (
          <tr key={program._id}>
            <td>{program.name}</td>
            <td>
              <Button value={program._id} onClick={props.viewQuestions}>
                View Questions
              </Button>
            </td>
            <td>
              <Button value={program._id} onClick={props.deleteProgram}>
                Delete Program
              </Button>
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td>No Programs Exist</td>
        </tr>
      )}
    </tbody>
  </Table>
);

export default ProgramList;
