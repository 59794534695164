import React from "react";
import {
    NavbarBrand,
    Navbar
  } from "reactstrap";

  const AnnouncementNav = props => (
    <Navbar color="faded" light>
    <NavbarBrand href="/sk/announcements" className="mr-auto">
      스와니 회중 온라인 게시판
    </NavbarBrand>
    {/* <NavbarToggler onClick={props.toggleNavbar} className="mr-2" />
    <Collapse isOpen={props.collapsed} navbar>
      <Nav navbar>
        <NavItem>
          <NavLink href="/sk/announcements">Home</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/sk/announcements/meetings">집회</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/sk/announcements/service">봉사</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/sk/announcements/upcoming">
            주요 일정
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/sk/announcements/other">
            일반 게시판
          </NavLink>
        </NavItem>
        <NavItem onClick={props.logOut}>
          Log Out <i className="fas fa-sign-out-alt"></i>
        </NavItem>
      </Nav>
    </Collapse> */}
  </Navbar>
  )

  export default AnnouncementNav;