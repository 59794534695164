import React from "react";
import { Button } from "reactstrap";

const QuestionCard = props => (
  <tr>
    <td className="qNameCol">{props.qName}</td>
    {props.availability ? (
      <td className="rightCol">
        <Button color="success" className="registerBtn" onClick={props.toggle} value={props._id}>신청하기</Button>
      </td>
    ) : (
      <td className="rightCol">{props.pubName}</td>
    )}
  </tr>
);

export default QuestionCard;
