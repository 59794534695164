import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Input,
  Alert
} from "reactstrap";

const RegisterModal = props => (
  <Modal isOpen={props.registerModal} toggle={props.toggle}>
    <ModalHeader toggle={props.toggle}>{props.activeQName} 신청하세요</ModalHeader>
    <ModalBody>
      {props.rAlert ? (
        <Alert color="danger">이름을 입력해주세요!</Alert>
      ) : (
        ""
      )}
      <Form>
        <FormGroup>
          <Input
            value={props.inputName}
            onChange={props.changeHandler}
            name="inputName"
            placeholder="이름을 입력하세요"
          />
        </FormGroup>
      </Form>
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={props.registerQuestion}>
        신청하기
      </Button>{" "}
      <Button color="secondary" onClick={props.toggle}>
        취소
      </Button>
    </ModalFooter>
  </Modal>
);

export default RegisterModal;
