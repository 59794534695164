import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import HomePage from "./components/homePage/HomePage";
import ProgramPage from "./components/programPage/ProgramPage";
import Announcepage from "./components/announcePage/AnnouncePage";
import LoginPage from "./components/loginPage/LoginPage";
import UserContext from "./context/UserContext";
import ProtectedRoute from "./ProtectedRoute";
import Auth from "./utils/Auth";
import axios from "axios";
import AdminPage from "./components/adminPage/AdminPage";
// import MeetingTab from "./components/announcePage/tabs/MeetingTab";
// import ServiceTab from "./components/announcePage/tabs/ServiceTab";
// import UpcomingTab from "./components/announcePage/tabs/UpcomingTab";
// import OtherTab from "./components/announcePage/tabs/OtherTab";
import RedirectPage from "./components/redirectPage/RedirectPage";
// import VidPage from "./components/vidPage/VidPage";

class App extends React.Component {
  state = {
    user: null,
  };

  setUser = (userID, token) => {
    axios({
      method: "GET",
      url: `/api/users/${userID}`,
      headers: { Authorization: `Bearer ${token}` },
    }).then((user) => {
      this.setState({ user: user.data });
    });
  };

  componentDidMount() {
    const userID = localStorage.getItem("userID");
    const token = localStorage.getItem("token");
    if (token && userID) {
      this.setUser(userID, token);
    }
  }

  logOut = (event) => {
    event.preventDefault();
    Auth.logOut((response) => console.log(response));
    window.location.replace("/sk");
  };

  render() {
    const { user } = this.state;

    return (
      <div className="actualBody">
        <Switch>
          {user && user.admin ? (
            <UserContext.Provider value={{ user: user, logOut: this.logOut }}>
              <ProtectedRoute path="/sk/program/:id" component={ProgramPage} />
              {/* <ProtectedRoute exact path="/sk" component={HomePage} /> */}
              <Route exact path="/sk/login">
                <LoginPage {...this.props} />
              </Route>
              <Route exact path="/special">
                <RedirectPage {...this.props} />
              </Route>
              <ProtectedRoute exact path="/sk/admin" component={AdminPage} />
              {/* <ProtectedRoute
                exact
                path="/sk/video"
                component={VidPage}
              /> */}
              <ProtectedRoute
                exact
                path="/sk/announcements"
                component={Announcepage}
              />
              {/* <ProtectedRoute
                exact
                path="/sk/announcements/meetings"
                component={MeetingTab}
              />
              <ProtectedRoute
                exact
                path="/sk/announcements/service"
                component={ServiceTab}
              /> */}
              {/* <ProtectedRoute
                exact
                path="/sk/announcements/upcoming"
                component={UpcomingTab}
              /> */}
              {/* <ProtectedRoute
                exact
                path="/sk/announcements/other"
                component={OtherTab}
              /> */}
            </UserContext.Provider>
          ) : (
            <UserContext.Provider value={{ user: user, logOut: this.logOut }}>
              <ProtectedRoute path="/sk/program/:id" component={ProgramPage} />
              <ProtectedRoute exact path="/sk" component={HomePage} />
              <Route exact path="/sk/login">
                <LoginPage {...this.props} />
              </Route>
              <Route exact path="/special">
                <RedirectPage {...this.props} />
              </Route>
              <ProtectedRoute
                exact
                path="/sk/announcements"
                component={Announcepage}
              />
              {/* <ProtectedRoute
                exact
                path="/sk/video"
                component={VidPage}
              /> */}
              {/* <ProtectedRoute
                exact
                path="/sk/announcements/meetings"
                component={MeetingTab}
              />
              <ProtectedRoute
                exact
                path="/sk/announcements/service"
                component={ServiceTab}
              />
              <ProtectedRoute
                exact
                path="/sk/announcements/upcoming"
                component={UpcomingTab}
              />
              <ProtectedRoute
                exact
                path="/sk/announcements/other"
                component={OtherTab}
              /> */}
            </UserContext.Provider>
          )}
        </Switch>
      </div>
    );
  }
}

export default withRouter(App);
