import React from "react";
import UserContext from "../../context/UserContext";
import Auth from "../../utils/Auth";
import {
  Container,
  Form,
  Input,
  Button,
  Alert,
  Row,
  Col,
} from "reactstrap";

class LoginPage extends React.Component {
  static contextType = UserContext;

  state = {
    password: "",
    wrongLogin: false,
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  submitHandler = (event) => {
    event.preventDefault();
    const username = this.state.password;
    const password = this.state.password;
    if (username && password) {
      Auth.logIn(username, password, (response) => {
        if (!response.error) {
          this.props.history.push("/sk/announcements");
          window.location.reload();
        } else {
          this.setState({ wrongLogin: true });
        }
      });
    }
  };

  render() {
    return (
      <Container>
        <Row>
          <Col md="4" sm="0"></Col>
          <Col md="4" sm="12">
            <h1 className="loginTitle">로그인</h1>
          </Col>
          <Col md="4" sm="0"></Col>
        </Row>
        <Row>
          <Col md="4" sm="0"></Col>
          <Col md="4" sm="12">
            <hr />
          </Col>
          <Col md="4" sm="0"></Col>
        </Row>
        <Row>
          <Col md="4" sm="0"></Col>
          <Col md="4" sm="12">
            {this.state.wrongLogin ? (
              <Alert color="warning">비밀번호가 틀렸습니다!</Alert>
            ) : (
              ""
            )}
          </Col>
          <Col md="4" sm="0"></Col>
        </Row>
        <Form onSubmit={this.submitHandler}>
          <Row>
            <Col md="4" sm="0"></Col>
            <Col md="4" sm="12">
              <Input
                type="password"
                name="password"
                value={this.state.password}
                onChange={this.changeHandler}
                placeholder="비밀번호를 입력하세요"
              />
            </Col>
            <Col md="4" sm="0"></Col>
          </Row>
          <Row>
            <Col md="4" sm="0"></Col>
            <Col md="4" sm="12">
              <Button type="submit" color="primary" className="loginBtn">
                로그인
              </Button>
            </Col>
            <Col md="4" sm="0"></Col>
          </Row>
        </Form>
      </Container>
    );
  }
}

export default LoginPage;
