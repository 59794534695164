import React from "react";
import { Card, CardTitle, CardBody, Button, CardImg } from "reactstrap";

const DonationsCard = (props) => (
  <Card>
    <CardImg />
    <CardBody>
      <CardTitle>
        <h3>헌금</h3>
      </CardTitle>
      <Button onClick={props.openDonations} className="dcBtn">
        헌금하기
      </Button>
    </CardBody>
  </Card>
);

export default DonationsCard;
