import React from "react";
import { Form, FormGroup, Label, Input, Button, Col } from "reactstrap";

const CreateProgramForm = (props) => (
  <Form onSubmit={props.createProgram}>
    <FormGroup row>
      <Label sm={3}>Program Name</Label>
      <Col sm={6}>
        <Input
          name="pName"
          onChange={props.changeHandler}
          placeholder="Enter Program Name"
          value={props.pName}
        />
      </Col>
      <Col sm={3}>
        <Button onClick={props.createProgram} className="createProgramBtn" color="success">Create</Button>
      </Col>
    </FormGroup>
  </Form>
);

export default CreateProgramForm;
