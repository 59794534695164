import React from "react";
import { Card, CardTitle, CardBody, Button, CardImg } from "reactstrap";

const MeetingCard = (props) => (
  <Card>
    <CardImg />
    <CardBody>
      <CardTitle>
        <h3>집회</h3>
      </CardTitle>
      <Button onClick={props.openZoomM} className="mcBtn" color="primary">
        집회 참석하기
      </Button>
    </CardBody>
  </Card>
);

export default MeetingCard;
