import React from "react";
import CreateProgramForm from "./CreateProgramForm";
import ProgramList from "./ProgramList";
import { Container, Row, Col, Alert } from "reactstrap";
import UserContext from "../../context/UserContext";
import axios from "axios";
import QuestionsModal from "./QuestionsModal";
// import AddPublisherForm from "./AddPublisherForm";
// import PublisherList from "./PublisherList";

class AdminPage extends React.Component {
  state = {
    pName: "",
    congName: "",
    qName: "",
    pID: "",
    programs: [],
    activeQuestions: [],
    questionsModal: false,
    activePName: "",
    isOpen: false,
    activePID: "",
    pubName: "",
    fsGroup: "",
    dropDown: false,
    rAlert1: false,
    gAlert1: false,
    rAlert2: false,
    gAlert2: false,
    yAlert: false,
    checked: false,
    publishers: [],
    pubAlert: false,
  };

  componentDidMount() {
    const url = window.location.pathname;
    const congName = url.substring(1, url.indexOf("/", 1));
    this.setState({ congName: congName });
    this.getPrograms();
    // this.getPublishers();
  }

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  toggleQuestionsModal = (event) => {
    this.setState({
      questionsModal: !this.state.questionsModal,
      gAlert1: false,
      rAlert1: false,
      rAlert2: false,
      gAlert2: false,
      yAlert: false,
    });
  };

  toggleCollapse = (event) => {
    this.setState({
      isOpen: !this.state.isOpen,
      gAlert2: false,
      rAlert2: false,
      yAlert: false,
    });
  };

  toggleDropDown = (event) => {
    this.setState({ dropDown: !this.state.dropDown });
  };

  getPrograms() {
    axios({
      url: "/api/programs",
      method: "GET",
    }).then((programs) => {
      const sortedPrograms = [];
      for (let i = 0; i < programs.data.length; i++) {
        if (
          programs.data[i].congregation.toLowerCase() === this.state.congName
        ) {
          sortedPrograms.push(programs.data[i]);
        }
      }
      this.setState({ programs: sortedPrograms, rAlert1: false });
    });
  }

  createProgram = (event) => {
    event.preventDefault();
    if (this.state.pName !== "") {
      axios({
        url: "/api/programs",
        method: "POST",
        data: {
          name: this.state.pName,
          congregation: this.state.congName,
        },
      }).then((program) => {
        this.setState({ gAlert1: true, pName: "" });
        this.getPrograms();
      });
    } else {
      this.setState({ rAlert1: true });
    }
  };

  viewQuestions = (event) => {
    event.preventDefault();
    for (let i = 0; i < this.state.programs.length; i++) {
      if (event.target.value === this.state.programs[i]._id) {
        this.setState({
          activeQuestions: this.state.programs[i].questions,
          activePName: this.state.programs[i].name,
          activePID: event.target.value,
        });
      }
    }
    this.toggleQuestionsModal();
  };

  createQuestion = (event) => {
    event.preventDefault();
    if (this.state.qName !== "") {
      axios({
        method: "POST",
        url: "/api/questions",
        data: {
          qName: this.state.qName,
          program: this.state.activePID,
        },
      }).then((question) => {
        const updatedQuestions = [];
        for (let i = 0; i < this.state.activeQuestions.length; i++) {
          updatedQuestions.push(this.state.activeQuestions[i]._id);
        }
        updatedQuestions.push(question.data._id);
        axios({
          method: "PUT",
          url: `/api/programs/${this.state.activePID}`,
          data: {
            questions: updatedQuestions,
          },
        }).then((program) => {
          this.setState({
            activeQuestions: program.data.questions,
            qName: "",
            rAlert2: false,
            gAlert2: true,
            yAlert: false,
          });
          this.getPrograms();
        });
      });
    } else {
      this.setState({ rAlert2: true, gAlert2: false, yAlert2: false });
    }
  };

  deleteQuestion = (event) => {
    event.preventDefault();
    axios({
      method: "DELETE",
      url: `/api/questions/${event.target.value}`,
    }).then((question) => {
      const updatedQuestions = [];
      for (let i = 0; i < this.state.activeQuestions.length; i++) {
        if (question.data._id !== this.state.activeQuestions[i]._id) {
          updatedQuestions.push(this.state.activeQuestions[i]._id);
        }
      }
      axios({
        method: "PUT",
        url: `/api/programs/${this.state.activePID}`,
        data: {
          questions: updatedQuestions,
        },
      }).then((program) => {
        this.setState({
          activeQuestions: program.data.questions,
          yAlert: true,
          gAlert2: false,
          rAlert2: false,
        });
        this.getPrograms();
      });
    });
  };

  deleteProgram = (event) => {
    event.preventDefault();
    axios({
      method: "DELETE",
      url: `/api/programs/${event.target.value}`,
    }).then((program) => {
      axios({
        method: "DELETE",
        url: "/api/questions",
        data: {
          program: program.data._id,
        },
      }).then((count) => {
        this.getPrograms();
      });
    });
  };

  // addPublisher = (event) => {
  //   event.preventDefault();
  //   if (this.state.pubName && this.state.fsGroup) {
  //     axios({
  //       method: "POST",
  //       url: "/api/publishers",
  //       data: {
  //         name: this.state.pubName,
  //         fsGroup: this.state.fsGroup,
  //         overseer: this.state.checked,
  //       },
  //     }).then((publisher) => {
  //       this.setState({ pubName: "", fsGroup: "", checked: false, pubAlert: false });
  //       this.getPublishers();
  //     });
  //   } else {
  //     this.setState({ pubAlert: true });
  //   }
  // };

  // getPublishers() {
  //   axios({
  //     method: "GET",
  //     url: "/api/publishers",
  //   }).then((publishers) => {
  //     this.setState({ publishers: publishers.data });
  //   });
  // }

  checkChange = (event) => {
    this.setState({ checked: event.target.checked });
  };

  render() {
    return (
      <UserContext.Consumer>
        {(UserContext) => (
          <Container>
            <Row>
              <Col>
                <header>
                  <h1 className="title">Admin Page</h1>
                  <i className="fas fa-times" onClick={UserContext.logOut}></i>
                </header>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <h3>Create Programs</h3>
                <hr />
                {this.state.rAlert1 ? (
                  <Alert color="danger">Please enter a program name!</Alert>
                ) : (
                  ""
                )}
                {this.state.gAlert1 ? (
                  <Alert color="success">Program created!</Alert>
                ) : (
                  ""
                )}
                <CreateProgramForm
                  createProgram={this.createProgram}
                  changeHandler={this.changeHandler}
                  pName={this.state.pName}
                />
              </Col>
              <Col>
                <h3>Program List</h3>
                <hr />
                <ProgramList
                  programs={this.state.programs}
                  viewQuestions={this.viewQuestions}
                  toggle={this.toggleDropDown}
                  dropDown={this.state.dropDown}
                  deleteProgram={this.deleteProgram}
                />
              </Col>
            </Row>
            {/* <Row>
              <Col>
                <h3>Add Publisher</h3>
                <hr />
                {this.state.pubAlert ? (
                  <Alert color="danger">
                    Please enter publisher name or field service group
                  </Alert>
                ) : (
                  ""
                )}
                <AddPublisherForm
                  changeHandler={this.changeHandler}
                  pubName={this.state.pubName}
                  fsGroup={this.state.fsGroup}
                  addPublisher={this.addPublisher}
                  checkChange={this.checkChange}
                />
              </Col>
              <Col>
                <h3>Publisher List</h3>
                <hr />
                <PublisherList publishers={this.state.publishers} />
              </Col>
            </Row> */}
            <QuestionsModal
              activePName={this.state.activePName}
              toggle={this.toggleQuestionsModal}
              questions={this.state.activeQuestions}
              questionsModal={this.state.questionsModal}
              toggleCollapse={this.toggleCollapse}
              isOpen={this.state.isOpen}
              changeHandler={this.changeHandler}
              qName={this.state.qName}
              createQuestion={this.createQuestion}
              rAlert2={this.state.rAlert2}
              gAlert2={this.state.gAlert2}
              yAlert={this.state.yAlert}
              deleteQuestion={this.deleteQuestion}
            />
          </Container>
        )}
      </UserContext.Consumer>
    );
  }
}

export default AdminPage;
