import React from "react";
import {
  Container,
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";
import UserContext from "../../context/UserContext";
import ServiceCard from "./cards/ServiceCard";
import MeetingCard from "./cards/MeetingCard";
// import CommentsCard from "./cards/CommentsCard";
import DonationsCard from "./cards/DonationsCard";
// import Ticker from "react-ticker";
import AnnouncementNav from "./AnnouncementNav";
import AnnouncementModal from "./AnnouncementModal";
import PioModal from "./PioModal";
import axios from "axios";

class Announcepage extends React.Component {
  state = {
    announcementModal: false,
    numPages: null,
    pageNumber: 1,
    pdfLink: "",
    collapsed: false,
    tickerMessage: "",
    pioAlert: false,
    emptyAlert: false,
    emptyAlert2: false,
    checked: false,
    announcementTitle: "",
    month: "",
    date: "",
    signature: "",
    displayCircuitAsemblyProgram: false,
  };

  componentDidMount() {
    this.checkCurrentDate();
  }

  checkCurrentDate() {
    const todaysDate = new Date();
    const date1 = new Date("October 22, 2021 00:00:00");
    const date2 = new Date("October 24, 2021 23:59:59");

    const todaysDateTime = todaysDate.getTime();
    const date1Time = date1.getTime();
    const date2Time = date2.getTime();
    if (todaysDateTime >= date1Time && todaysDateTime < date2Time) {
      this.setState({ displayCircuitAsemblyProgram: true });
    }
  }

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  checkChange = (event) => {
    this.setState({
      checked: event.target.checked,
    });
  };

  toggleNavbar = (event) => {
    event.preventDefault();
    this.setState({
      collapsed: !this.state.collapsed,
      pioAlert: false,
      emptyAlert: false,
      emptyAlert2: false,
    });
  };

  toggleAnnouncementModal = (event) => {
    this.setState({
      announcementModal: !this.state.announcementModal,
      pioAlert: false,
      emptyAlert: false,
      emptyAlert2: false,
    });
  };

  togglePioModal = (event) => {
    this.setState({
      pioModal: !this.state.pioModal,
      pioAlert: false,
      emptyAlert: false,
      emptyAlert2: false,
      month: "",
      signature: "",
      checked: false,
    });
  };

  setAnnouncement = (event) => {
    console.log(event.target.value);
    if (event.target.value === "/static/media/zoomHostSchedule.4bd24e93.pdf") {
      this.setState({
        pdfLink: event.target.value,
        announcementTitle: "주간 봉사 계획",
        announcementModal: !this.state.announcementModal,
        pageNumber: 1,
        pioAlert: false,
        emptyAlert: false,
        emptyAlert2: false,
      });
    } else if (
      event.target.value ===
      "/static/media/meetingAssignmentSchedule.8883a51d.pdf"
    ) {
      this.setState({
        pdfLink: event.target.value,
        announcementTitle: "Meeting Assignment Schedule",
        announcementModal: !this.state.announcementModal,
        pageNumber: 1,
        pioAlert: false,
        emptyAlert: false,
        emptyAlert2: false,
      });
    }
  };

  sendPioneerForm = async (event) => {
    event.preventDefault();
    if (this.state.month && this.state.date && this.state.signature) {
      let checked = "";
      if (this.state.checked) {
        checked = "checked";
      } else {
        checked = "unchecked";
      }
      const text = `월(들): ${this.state.month}\n\n
      다시 통지할 때까지 계속 보조 파이오니아로서 봉사하기를 원한다: ${checked}\n\n
      일자: ${this.state.date}\n\n
      이름: ${this.state.signature}`;
      const data = {
        from: "스와니 한국어 회중 <peter.jang@outlook.com>",
        to: "jseokjae@gmail.com",
        subject: `${this.state.signature} 보조 파이오니아 신청`,
        text: text,
      };
      await this.sendEmail(data);
      this.setState({
        pioModal: false,
        pioAlert: true,
        emptyAlert: false,
        checked: false,
        month: "",
        signature: "",
      });
    } else {
      this.setState({ emptyAlert: true, pioAlert: false });
    }
  };

  sendEmail(data) {
    axios({
      method: "POST",
      url: "/api/contact",
      data: data,
    });
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  goToPrevPage = () => {
    this.setState((state) => ({ pageNumber: state.pageNumber - 1 }));
  };

  goToNextPage = () => {
    this.setState((state) => ({ pageNumber: state.pageNumber + 1 }));
  };

  openZoomM() {
    window.open(
      "https://us02web.zoom.us/j/6565168846?pwd=c2pLaHFNajVLYW1lTHBqb01VcElTdz09",
      "_system"
    );
  }

  openZoomFS() {
    window.open(
      "https://us02web.zoom.us/j/9245595883?pwd=cVhpOVZoUlhvbHRTUXFvdWRhMXNTZz09",
      "_system"
    );
  }

  openTerritory() {
    window.open("https://suwanee-field-service.herokuapp.com");
  }

  // goComments() {
  //   window.location.href = "/sk";
  // }

  goDonate() {
    window.location = "https://donate.jw.org/ui/KO/donate-home.html#/donate";
  }

  // openCAProgram() {
  //   const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  //   // Windows Phone must come first because its UA also contains "Android"
  //   if (/windows phone/i.test(userAgent)) {
  //     window.location.href =
  //       "intent:fle.stream.jw.org/t/VGZVSk5wWTNqUjJWVld1dlR6MHlSWDF2OGovdlJ3M3ViVmFuc21peWVxZmFCSzhPU0VmelhpendCS2ttN2dXYw%3D%3D;end";
  //   } else if (/android/i.test(userAgent)) {
  //     window.location.href =
  //       "intent:fle.stream.jw.org/t/VGZVSk5wWTNqUjJWVld1dlR6MHlSWDF2OGovdlJ3M3ViVmFuc21peWVxZmFCSzhPU0VmelhpendCS2ttN2dXYw%3D%3D;end";
  //   } else if (
  //     navigator.platform.indexOf("iPhone") !== -1 ||
  //     navigator.platform.indexOf("iPad") !== -1 ||
  //     navigator.platform.indexOf("iPod") !== -1 ||
  //     navigator.platform.indexOf("MacIntel") !== -1
  //   ) {
  //     setTimeout(function () {
  //       window.location.href =
  //         "googlechrome://fle.stream.jw.org/t/VGZVSk5wWTNqUjJWVld1dlR6MHlSWDF2OGovdlJ3M3ViVmFuc21peWVxZmFCSzhPU0VmelhpendCS2ttN2dXYw%3D%3D";
  //     }, 100);

  //     window.location.href =
  //       "https://fle.stream.jw.org/t/VGZVSk5wWTNqUjJWVld1dlR6MHlSWDF2OGovdlJ3M3ViVmFuc21peWVxZmFCSzhPU0VmelhpendCS2ttN2dXYw%3D%3D";
  //   } else {
  //     window.location.href =
  //       "https://fle.stream.jw.org/t/VGZVSk5wWTNqUjJWVld1dlR6MHlSWDF2OGovdlJ3M3ViVmFuc21peWVxZmFCSzhPU0VmelhpendCS2ttN2dXYw%3D%3D";
  //   }
  // }

  render() {
    return (
      <UserContext.Consumer>
        {(UserContext) => (
          <Container>
            <AnnouncementNav
              collapsed={this.state.collapsed}
              toggleNavbar={this.toggleNavbar}
              logOut={UserContext.logOut}
            />
            <hr />
            {this.state.pioAlert ? (
              <Alert color="success">보조 파이오니아 신청하셨습니다!</Alert>
            ) : (
              ""
            )}
            {/* t */}
            <Row>
              <Col lg="6" md="12" sm="12" xs="12">
                <Card>
                  <CardBody>
                    <CardTitle>
                      <h3 className="h3st">구역 카드</h3>
                    </CardTitle>
                    <Button
                      color="primary"
                      className="stBtn"
                      onClick={this.openTerritory}
                    >
                      열기
                    </Button>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" md="12" sm="12" xs="12">
                <MeetingCard openZoomM={this.openZoomM} />
              </Col>
              <Col lg="6" md="12" sm="12" xs="12">
                <ServiceCard openZoomFS={this.openZoomFS} />
              </Col>
              <Col lg="6" md="12" sm="12" xs="12">
                <Card>
                  <CardBody>
                    <CardTitle>
                      <h3 className="h3st">보조 파이오니아 신청</h3>
                    </CardTitle>
                    <Button
                      color="success"
                      className="stBtn"
                      onClick={this.togglePioModal}
                    >
                      신청하기
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg="6" md="12" sm="12" xs="12">
                <DonationsCard openDonations={this.goDonate} />
              </Col>
              <Col lg="6" md="12" sm="12" xs="12"></Col>
            </Row>
            <AnnouncementModal
              pdfLink={this.state.pdfLink}
              toggle={this.toggleAnnouncementModal}
              announcementModal={this.state.announcementModal}
              onDocumentLoadSuccess={this.onDocumentLoadSuccess}
              goToPrevPage={this.goToPrevPage}
              goToNextPage={this.goToNextPage}
              pageNumber={this.state.pageNumber}
              numPages={this.state.numPages}
              announcementTitle={this.state.announcementTitle}
            />
            <PioModal
              pioModal={this.state.pioModal}
              toggle={this.togglePioModal}
              changeHandler={this.changeHandler}
              checkChange={this.checkChange}
              date={this.state.date}
              month={this.state.month}
              checked={this.state.checked}
              signature={this.state.signature}
              sendPioneerForm={this.sendPioneerForm}
              emptyAlert={this.state.emptyAlert}
            />
          </Container>
        )}
      </UserContext.Consumer>
    );
  }
}

export default Announcepage;
