import axios from "axios";

function Auth () {
	let loggedIn = false;

	function logIn (username, password, cb) {
		axios({
			url: "/api/session",
			method: "POST",
			data: {
				username: username,
				password: password
			}
		}).then((response) => {
			localStorage.setItem("token", response.data.token);
			localStorage.setItem("userID", response.data.id);
			loggedIn = true;
			cb(response.data);
		})
	}

	function logOut (cb) {
		localStorage.removeItem("token");
		localStorage.removeItem("userID");
		loggedIn = false;
		cb("You have logged out");
	}

	function getToken () {
		return localStorage.getItem("token");
	}

	function isLoggedIn () {
		getToken() ? loggedIn = true : loggedIn = false;
		return loggedIn;
	}

	return {
		isLoggedIn,
		logIn,
		logOut,
		getToken
	}
}

export default Auth();