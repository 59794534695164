import React from "react";
import UserContext from "../../context/UserContext";
import { Container, Row, Col } from "reactstrap";

class RedirectPage extends React.Component {
  componentDidMount() {
    this.openCAProgram();
  }

  openCAProgram() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      window.location.href =
        "intent:studio.stream.jw.org/t/b1cc6b8df381756ec81cffe22a4b0e3c8bb6cec8ad355d149fed868c3e6a02f6#Intent;scheme=http;package=com.android.chrome;end";
    } else if (/android/i.test(userAgent)) {
      window.location.href =
        "intent:studio.stream.jw.org/t/b1cc6b8df381756ec81cffe22a4b0e3c8bb6cec8ad355d149fed868c3e6a02f6#Intent;scheme=http;package=com.android.chrome;end";
    } else if (
      navigator.platform.indexOf("iPhone") !== -1 ||
      navigator.platform.indexOf("iPad") !== -1 ||
      navigator.platform.indexOf("iPod") !== -1 ||
      navigator.platform.indexOf("MacIntel") !== -1
    ) {
      // window.location.href =
      //   "googlechrome://studio.stream.jw.org/t/3812b13e6225a7b4f0c47e8a9687e5e5bd4b181ab1464e15af1fadc1d1f3358d";
      window.open("https://studio.stream.jw.org/t/b1cc6b8df381756ec81cffe22a4b0e3c8bb6cec8ad355d149fed868c3e6a02f6", "_self");
    } else {
      window.open(
        "https://studio.stream.jw.org/t/b1cc6b8df381756ec81cffe22a4b0e3c8bb6cec8ad355d149fed868c3e6a02f6",
        "_self"
      );
    }
  }

  render() {
    return (
      <UserContext.Consumer>
        {(UserContext) => (
          <Container>
            <Row>
              <Col>RedirectPage</Col>
            </Row>
          </Container>
        )}
      </UserContext.Consumer>
    );
  }
}

export default RedirectPage;
