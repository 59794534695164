import React from "react";
import { Card, CardTitle, CardBody, Button, CardImg } from "reactstrap";

const ServiceCard = (props) => (
  <Card>
    <CardImg />
    <CardBody>
      <CardTitle>
        <h3>봉사</h3>
      </CardTitle>
      <Button onClick={props.openZoomFS} className="scBtn" color="primary">
        봉사 참여하기
      </Button>
    </CardBody>
  </Card>
);

export default ServiceCard;
